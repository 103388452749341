<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            type="year"
            default="today"
            label="기준년도"
            name="wasteYear"
            v-model="searchParam.wasteYear"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="월별 생산량"
      tableId="processManual"
      :columns="grid2.columns"
      :gridHeightAuto="true"
      :data="grid2.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :expandAll="true"
      :hideBottom="true"
      topBorderClass="topcolor-green"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList"/>
        </q-btn-group>
      </template>
    </c-table>
    <c-table
      ref="table"
      title="폐기물 발생량(kg) / 처리비용(원)"
      tableId="processManual"
      :columns="grid.columns"
      :gridHeightAuto="true"
      :data="grid.data"
      :merge="grid.merge"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :expandAll="true"
      :hideBottom="true"
    >
    </c-table>
    <!-- <c-table
      ref="table"
      title="월별 처리비용 (원)"
      tableId="processManual"
      :columns="grid4.columns"
      :merge="grid4.merge"
      :gridHeightAuto="true"
      :data="grid4.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :expandAll="true"
      :hideBottom="true"
      topBorderClass="topcolor-orange"
    >
    </c-table> -->
    <c-table
      ref="table"
      title="폐기물 원단위 분석 - 1대당 발생량(kg) / 1대당 처리비용(원)"
      tableId="processManual"
      :columns="grid3.columns"
      :gridHeightAuto="true"
      :data="grid3.data"
      :merge="grid3.merge"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :expandAll="true"
      :hideBottom="true"
      topBorderClass="topcolor-orange"
    >
    </c-table>
    <!-- <c-table
      ref="table"
      title="폐기물 원단위 분석 (처리비용)"
      tableId="processManual"
      :columns="grid5.columns"
      :gridHeightAuto="true"
      :data="grid5.data"
      :merge="grid5.merge"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :expandAll="true"
      :hideBottom="true"
      topBorderClass="topcolor-orange"
    >
    </c-table> -->
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'waste-uc-manage',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'plantName' },
          { index: 1, colName: 'envWasteMstId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'envWasteMstName',
            field: 'envWasteMstName',
            label: '폐기물 종류',
            style: 'width: 160px',
            type: 'ellipsistd',
            align: 'left',
            sortable: false,
          },
          {
            name: 'tvUnit',
            field: 'tvUnit',
            label: '구분',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'tv01',
            field: 'tv01',
            label: '1월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv02',
            field: 'tv02',
            label: '2월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv03',
            field: 'tv03',
            label: '3월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv04',
            field: 'tv04',
            label: '4월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv05',
            field: 'tv05',
            label: '5월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv06',
            field: 'tv06',
            label: '6월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv07',
            field: 'tv07',
            label: '7월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv08',
            field: 'tv08',
            label: '8월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv09',
            field: 'tv09',
            label: '9월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv10',
            field: 'tv10',
            label: '10월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv11',
            field: 'tv11',
            label: '11월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv12',
            field: 'tv12',
            label: '12월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tvtotal',
            field: 'tvtotal',
            label: 'Total',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
        ],
        height: '300px',
        data: [],
      },
      grid2: {
        merge: [
          { index: 0, colName: 'plantName' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 270px',
            sortable: false,
          },
          {
            name: 'tvUnit',
            field: 'tvUnit',
            label: 'LBLUNIT',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'tv01',
            field: 'tv01',
            label: '1월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv02',
            field: 'tv02',
            label: '2월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv03',
            field: 'tv03',
            label: '3월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv04',
            field: 'tv04',
            label: '4월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv05',
            field: 'tv05',
            label: '5월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv06',
            field: 'tv06',
            label: '6월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv07',
            field: 'tv07',
            label: '7월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv08',
            field: 'tv08',
            label: '8월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv09',
            field: 'tv09',
            label: '9월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv10',
            field: 'tv10',
            label: '10월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv11',
            field: 'tv11',
            label: '11월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv12',
            field: 'tv12',
            label: '12월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tvtotal',
            field: 'tvtotal',
            label: 'Total',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
        ],
        height: '180px',
        data: [],
      },
      grid4: {
        merge: [
          { index: 0, colName: 'plantName' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'envWasteMstName',
            field: 'envWasteMstName',
            label: '폐기물 종류',
            style: 'width: 160px',
            type: 'ellipsistd',
            align: 'left',
            sortable: false,
          },
          {
            name: 'tvUnit',
            field: 'tvUnit',
            label: '단가(원/톤)',
            align: 'right',
            type: 'cost',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'tv01',
            field: 'tv01',
            label: '1월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv02',
            field: 'tv02',
            label: '2월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv03',
            field: 'tv03',
            label: '3월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv04',
            field: 'tv04',
            label: '4월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv05',
            field: 'tv05',
            label: '5월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv06',
            field: 'tv06',
            label: '6월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv07',
            field: 'tv07',
            label: '7월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv08',
            field: 'tv08',
            label: '8월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv09',
            field: 'tv09',
            label: '9월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv10',
            field: 'tv10',
            label: '10월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv11',
            field: 'tv11',
            label: '11월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv12',
            field: 'tv12',
            label: '12월',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tvtotal',
            field: 'tvtotal',
            label: 'Total',
            type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
        ],
        height: '180px',
        data: [],
      },
      grid3: {
        merge: [
          { index: 0, colName: 'plantName' },
          { index: 1, colName: 'envWasteMstId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'envWasteMstName',
            field: 'envWasteMstName',
            label: '폐기물 종류',
            style: 'width: 160px',
            type: 'ellipsistd',
            align: 'left',
            sortable: false,
          },
          {
            name: 'tvUnit',
            field: 'tvUnit',
            label: '구분',
            align: 'center',
            style: 'width: 80px',
            sortable: false,
          },
          {
            name: 'tv01',
            field: 'tv01',
            label: '1월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv02',
            field: 'tv02',
            label: '2월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv03',
            field: 'tv03',
            label: '3월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv04',
            field: 'tv04',
            label: '4월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv05',
            field: 'tv05',
            label: '5월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv06',
            field: 'tv06',
            label: '6월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv07',
            field: 'tv07',
            label: '7월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv08',
            field: 'tv08',
            label: '8월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv09',
            field: 'tv09',
            label: '9월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv10',
            field: 'tv10',
            label: '10월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv11',
            field: 'tv11',
            label: '11월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv12',
            field: 'tv12',
            label: '12월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          // {
          //   name: 'tvtotal',
          //   field: 'tvtotal',
          //   label: 'Total',
          //   // type: 'cost',
          //   align: 'right',
          //   style: 'width: 90px',
          //   sortable: false,
          // },
        ],
        height: '300px',
        data: [],
      },
      grid5: {
        merge: [
          { index: 0, colName: 'plantName' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width: 120px',
            sortable: false,
          },
          {
            name: 'envWasteMstName',
            field: 'envWasteMstName',
            label: '폐기물 종류',
            style: 'width: 210px',
            type: 'ellipsistd',
            align: 'left',
            sortable: false,
          },
          // {
          //   name: 'tvUnit',
          //   field: 'tvUnit',
          //   label: 'LBLUNIT',
          //   align: 'center',
          //   style: 'width: 60px',
          //   sortable: false,
          // },
          {
            name: 'tv01',
            field: 'tv01',
            label: '1월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv02',
            field: 'tv02',
            label: '2월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv03',
            field: 'tv03',
            label: '3월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv04',
            field: 'tv04',
            label: '4월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv05',
            field: 'tv05',
            label: '5월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv06',
            field: 'tv06',
            label: '6월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv07',
            field: 'tv07',
            label: '7월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv08',
            field: 'tv08',
            label: '8월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv09',
            field: 'tv09',
            label: '9월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv10',
            field: 'tv10',
            label: '10월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv11',
            field: 'tv11',
            label: '11월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tv12',
            field: 'tv12',
            label: '12월',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
          {
            name: 'tvtotal',
            field: 'tvtotal',
            label: 'Total',
            // type: 'cost',
            align: 'right',
            style: 'width: 90px',
            sortable: false,
          },
        ],
        height: '300px',
        data: [],
      },
      searchParam: {
        wasteYear: '',
        plantCd: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.waste.volume.uc.url;
      this.getList();
    },
    getList() {
      if (this.searchParam.wasteYear) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = this.$_.filter(_result.data, {tvTypeCdTop : 'Z', totalFlag: 'X' });
          this.grid2.data = this.$_.filter(_result.data, {tvTypeCdTop : 'F', totalFlag: 'X' });
          this.grid3.data = this.$_.filter(_result.data, {tvTypeCdTop : 'O', totalFlag: 'X' });
          this.grid4.data = this.$_.filter(_result.data, {tvTypeCdTop : 'J', totalFlag: 'X' });
          this.grid5.data = this.$_.filter(_result.data, {tvTypeCdTop : 'Q', totalFlag: 'X' });
        },);
      } else {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message:
          '기준년도를 선택하세요',
          type: 'warning', // success / info / warning / error
        });
      }
    },
  }
};
</script>
